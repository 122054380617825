export enum CatalogUnitType {
  Unit = 0,
  Apt = 1,
  Home = 2,
  Lobby = 4,
  None = 5,
}

interface CatalogEntryVM {
  displayName: string;
  unitType: CatalogUnitType;
  entryId: string;
  siteId: number;
  fullName: string;
  siteName: string;
  userId: string;
  id: string;
  floorId: string;
  floorName: string;
  intercomCallMode: IntercomCallModeType;
  from: string;
  to: string;
  cronExpressionDND: string;
  enableDND: boolean;
  timeDurationDND: number;
}
export interface CatalogEntryModel extends CatalogEntryVM {
  //
}

export enum IntercomCallModeType {
  MobileApp = 0,
  CallLandline = 1,
}

interface PhoneCallVM {
  id: string;
  callId: string;
  accountId: number;
  serialNumber: string;
  deviceName: string;
  userId: string;
  fullName: string;
  siteName: string;
  registeredAt: string;
  timeZoneAbbreviation: string;
  siteId: number;
  isCompleted: boolean;
  callResult: PhoneCallStatus;
  accessGranted: boolean;
}
export interface PhoneCallModel extends PhoneCallVM {
  //
}
export enum PhoneCallStatus {
  Initiated = 0,
  Ringing = 1,
  Answered = 2,
  Rejected = 3,
  Hangup = 4,
  TimedOut = 5,
  AccessGranted = 6,
}
