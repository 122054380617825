import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import findIndex from "lodash/findIndex";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { signOutUser } from "../../../store/actions";
import { generalPortalRoutes } from "../../Utils/Routes/routes";
import { hasSomeAuthorization } from "../../Auth/actionsCheck";
import { PathStateObject } from "../../Utils/Models/routesModel";
import { iconColor } from "../../../assets/theme/palette";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";
import Data from "../../Utils/Constants/values";
import { PortalIds } from "../../Utils/Models/account";
import * as actions from "../../../store/actions";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    listRoot: {
      [theme.breakpoints.up("sm")]: {
        backgroundColor: theme.palette.background.default,
      },
      padding: 0,
    },
    listItemRoot: {
      [theme.breakpoints.up("sm")]: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    overrideButtonListItem: {
      color: theme.palette.grey[500],
      borderLeft: "3px solid transparent",
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
        borderLeft: "3px solid " + theme.palette.primary.main,
      },
    },
    overrideSelectedListItem: {
      borderLeft: "3px solid " + theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.dark + "!important",
      color: "#fff !important",
      [theme.breakpoints.up("sm")]: {
        backgroundColor: theme.palette.background.default + "!important",
        color: theme.palette.secondary.main + "!important",
      },
    },
    overrideRootListItemIcon: {
      color: iconColor + " !important",
    },
    overrideRootListItemIconSelected: {
      color: theme.palette.common.white + "!important",
      [theme.breakpoints.up("sm")]: {
        color: theme.palette.primary.main + "!important",
      },
    },
    overrideRootListItemTypography: {
      paddingLeft: "5px",
    },
    secondListContainer: {
      display: "flex",
      position: "absolute",
      bottom: 0,
      width: "100%",
      overflow: "hidden",
    },
    hideText: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    roundedBeforeItem: {
      [theme.breakpoints.up("sm")]: {
        borderRadius: "0px 0px 20px 0px",
      },
    },
    roundedAfterItem: {
      [theme.breakpoints.up("sm")]: {
        borderRadius: "0px 20px 0px 0px",
      },
    },
  });
});
interface Props {
  scopes: string[];
  accountFullName?: string;
  portal: string;
  userName?: string;
}
const CreateNavLinks = ({ scopes, accountFullName, portal, userName }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory(); //createBrowserHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [selectedList, setSelectedList] = useState(window.location.pathname);
  const { t } = useTranslation();

  useEffect(() => {
    const path = window.location.pathname;
    const portalType = path.includes("cameras")
      ? PortalIds.Cameras
      : path.includes("gateway")
      ? PortalIds.Gateway
      : PortalIds.AccessControl;

    dispatch(actions.changePortal(portalType));
  }, []);

  const routeItems = generalPortalRoutes.filter((route) => {
    const path = window.location.pathname;
    const portalType = path.includes("cameras")
      ? PortalIds.Cameras
      : path.includes("gateway")
      ? PortalIds.Gateway
      : PortalIds.AccessControl;

    return (
      route.type === portalType &&
      !route.skipSideBarMenu &&
      hasSomeAuthorization(route.authorization, scopes)
    );
  });

  const indexRouteSelected = findIndex(routeItems, (route) => route.path === selectedList);
  const latestRouteItem = routeItems.length - 1;

  useEffect(() => {
    setSelectedList(location.pathname);
  }, [location.pathname]);
  const [openLegal, setOpenLegal] = useState(false);

  return (
    <Fragment>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.listRoot}>
        <ListItem
          classes={{
            root: `${classes.listItemRoot} ${
              indexRouteSelected === 0 && classes.roundedBeforeItem
            }`,
          }}
        />
        {routeItems.map((route, index) => {
          return (
            <Fragment key={route.id}>
              <ListItem
                button
                key={route.id + "-List"}
                selected={selectedList == route.path}
                onClick={() => {
                  if (selectedList == route.path) {
                    history.replace(route.path, {
                      from: location.pathname,
                    } as PathStateObject);
                  } else {
                    history.push(route.path, {
                      from: location.pathname,
                    } as PathStateObject);
                  }
                  // setSelectedList(route.path)
                }}
                classes={{
                  root: `${classes.listItemRoot} ${
                    indexRouteSelected !== -1 &&
                    index === indexRouteSelected - 1 &&
                    classes.roundedBeforeItem
                  } ${
                    indexRouteSelected !== -1 &&
                    index === indexRouteSelected + 1 &&
                    classes.roundedAfterItem
                  }`,
                  selected: classes.overrideSelectedListItem,
                  button: classes.overrideButtonListItem,
                }}
              >
                <ListItemIcon style={{ minWidth: "38px" }}>
                  {
                    <route.sideBarNavIcon
                      classes={{
                        root: clsx(classes.overrideRootListItemIcon, {
                          [classes.overrideRootListItemIconSelected]: selectedList == route.path,
                        }),
                      }}
                    />
                  }
                </ListItemIcon>

                <ListItemText
                  primaryTypographyProps={{
                    classes: {
                      root: classes.overrideRootListItemTypography,
                    },
                    noWrap: true,
                    // color: "primary"
                  }}
                  style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                  primary={t("" + route.textLangSidebarNav)}
                />
              </ListItem>
            </Fragment>
          );
        })}
        <ListItem
          classes={{
            root: `${classes.listItemRoot} ${
              indexRouteSelected === latestRouteItem && classes.roundedAfterItem
            }`,
          }}
        />
      </List>
      <Divider />
      <Hidden smUp implementation="js">
        <div className={classes.secondListContainer}>
          <List
            disablePadding
            style={{
              width: "100%",
              backgroundColor: openLegal ? theme.palette.secondary.dark : undefined,
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {generalPortalRoutes.map((route) => {
              return (
                <Fragment key={route.id}>
                  {route.addToSideBarMenuInMobile ? (
                    hasSomeAuthorization(route.authorization, scopes) ? (
                      <ListItem
                        button
                        key={route.id + "-List-mobile"}
                        selected={selectedList == route.path}
                        onClick={() => {
                          if (selectedList == route.path) {
                            history.replace(route.path, {
                              from: location.pathname,
                            } as PathStateObject);
                          } else {
                            history.push(route.path, {
                              from: location.pathname,
                            } as PathStateObject);
                          }
                          // setSelectedList(route.path)
                        }}
                        classes={{
                          selected: classes.overrideSelectedListItem,
                          button: classes.overrideButtonListItem,
                        }}
                      >
                        <ListItemIcon style={{ minWidth: "38px" }}>
                          {
                            <route.sideBarNavIcon
                              classes={{
                                root: clsx(classes.overrideRootListItemIcon, {
                                  [classes.overrideRootListItemIconSelected]:
                                    selectedList == route.path,
                                }),
                              }}
                            />
                          }
                        </ListItemIcon>

                        <ListItemText
                          primaryTypographyProps={{
                            classes: {
                              root: classes.overrideRootListItemTypography,
                            },
                          }}
                          className={classes.hideText}
                          // style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                          primary={t("" + route.textLangSidebarNav)}
                        />
                      </ListItem>
                    ) : null
                  ) : null}
                </Fragment>
              );
            })}

            <ListItem
              button
              onClick={() => dispatch(signOutUser())}
              classes={{
                selected: classes.overrideSelectedListItem,
                button: classes.overrideButtonListItem,
              }}
            >
              <ListItemIcon style={{ minWidth: "38px" }}>
                <ExitToAppIcon
                  classes={{
                    root: classes.overrideRootListItemIcon,
                  }}
                />
              </ListItemIcon>

              <ListItemText
                primaryTypographyProps={{
                  classes: {
                    root: classes.overrideRootListItemTypography,
                  },
                }}
                className={classes.hideText}
                primary={t("SignOut")}
              />
            </ListItem>
            {/* //////////////////////////////////////*/}

            <ListItem
              classes={{
                selected: classes.overrideSelectedListItem,
                button: classes.overrideButtonListItem,
              }}
              button
              onClick={(event) => {
                // event?.preventDefault();
                event?.stopPropagation();
                setOpenLegal((prev) => !prev);
              }}
            >
              <ListItemText primary={t("Legal")} />
              {openLegal ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openLegal} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ backgroundColor: theme.palette.grey[50] }}
              >
                <ListItem
                  dense
                  button
                  onClick={() => {
                    window.open(Data.PRIVACY_MAIN, "_blank", "noopener");
                  }}
                >
                  <ListItemText primary={t("MainServicesAgreement")} />
                </ListItem>
                <ListItem
                  dense
                  button
                  onClick={() => {
                    window.open(Data.PRIVACY_BIO, "_blank", "noopener");
                  }}
                >
                  <ListItemText primary={t("BiometricPrivacyConsent")} />
                </ListItem>
              </List>
            </Collapse>
            {/* ////////////////////////////////// */}
          </List>
        </div>
      </Hidden>
    </Fragment>
  );
};
export default CreateNavLinks;
