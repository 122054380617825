// import red from '@material-ui/core/colors/red';
import { lightPalette, darkPalette } from "./palette";
import { lightTypography } from "./typography";
import overrides from "./overrides";

export const sideBarNavWidth = 210;
export const sideBarNavMiniWidth = 63;
export const sideBarNavCameraWidth = 27;
export const sideBarNavCameraMiniWidth = 5;

export const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

// A custom theme for this app
export const lightTheme = {
  // palette: { //https://material-ui.com/customization/palette/

  palette: lightPalette,
  typography: lightTypography,
  // overrides, //https://material-ui.com/customization/components/
  // zIndex: {
  //   appBar: 1200,
  //   drawer: 1100
  // }
  overrides: overrides,
};
export const blackTheme = {
  // palette: { //https://material-ui.com/customization/palette/
  //   // type: 'dark',

  palette: darkPalette,
  // typography,
  // overrides, //https://material-ui.com/customization/components/
  // zIndex: {
  //   appBar: 1200,
  //   drawer: 1100
  // }
};
