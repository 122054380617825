export const SIDEBARNAV_POSITION = "SIDEBARNAV_POSITION";
export const SIDEBARNAVCAMERAS_POSITION = "SIDEBARNAVCAMERAS_POSITION";
export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_WINDOW_DIMENSSIONS = "CHANGE_WINDOW_DIMENSSIONS";

export const SHOW_LOADING_COMPONENT = "SHOW_LOADING_COMPONENT";

export const SHOW_ERROR_TEXT_INTERCEPTOR = "SHOW_ERROR_TEXT_INTERCEPTOR";

//Auth
export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const SIGNOUT_REQUEST = "SIGNOUT_REQUEST";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_FAILURE = "SIGNOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const REAUTHENTICATE_CREDENTIAL_REQUEST = "REAUTHENTICATE_CREDENTIAL_REQUEST";
export const REAUTHENTICATE_CREDENTIAL_SUCCESS = "REAUTHENTICATE_CREDENTIAL_SUCCESS";
export const REAUTHENTICATE_CREDENTIAL_FAILURE = "REAUTHENTICATE_CREDENTIAL_FAILURE";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const VERIFY_PASSWORD_RESETCODE_REQUEST = "VERIFY_PASSWORD_RESETCODE_REQUEST";
export const VERIFY_PASSWORD_RESETCODE_SUCCESS = "VERIFY_PASSWORD_RESETCODE_SUCCESS";
export const VERIFY_PASSWORD_RESETCODE_FAILURE = "VERIFY_PASSWORD_RESETCODE_FAILURE";

export const CONFIRM_PASSWORD_RESET_REQUEST = "CONFIRM_PASSWORD_RESET_REQUEST";
export const CONFIRM_PASSWORD_RESET_SUCCESS = "CONFIRM_PASSWORD_RESET_SUCCESS";
export const CONFIRM_PASSWORD_RESET_FAILURE = "CONFIRM_PASSWORD_RESET_FAILURE";

export const SEND_VERIFICATION_EMAIL_REQUEST = "SEND_VERIFICATION_EMAIL_REQUEST";
export const SEND_VERIFICATION_EMAIL_SUCCESS = "SEND_VERIFICATION_EMAIL_SUCCESS";
export const SEND_VERIFICATION_EMAIL_FAILURE = "SEND_VERIFICATION_EMAIL_FAILURE";

export const UPDATE_TOKEN = "UPDATE_TOKEN";

export const UPDATE_USER_ACCESS = "UPDATE_USER_ACCESS";

//notifications PUSH_NOTIFICATION
export const PUSH_NOTIFICATION_PERMISSION_REQUEST = "PUSH_NOTIFICATION_PERMISSION_REQUEST";
export const PUSH_NOTIFICATION_PERMISSION_SUCCESS = "PUSH_NOTIFICATION_PERMISSION_SUCCESS";
export const PUSH_NOTIFICATION_PERMISSION_FAILURE = "PUSH_NOTIFICATION_PERMISSION_FAILURE";
export const PUSH_NOTIFICATION_MESSAGE = "PUSH_NOTIFICATION_MESSAGE";

export const PUSH_NOTIFICATION_DELETED_TOKEN = "PUSH_NOTIFICATION_DELETED_TOKEN";

export const PUSH_NOTIFICATION_UNSUBSCRIBE = "PUSH_NOTIFICATION_UNSUBSCRIBE";

export const PUSH_NOTIFICATION_READ_ALL = "PUSH_NOTIFICATION_READ_ALL";
export const PUSH_NOTIFICATION_REMOVE_ALL = "PUSH_NOTIFICATION_REMOVE_ALL";

//notifications Firestore
export const FIRESTORE_FACEENROLLMENT_ADD = "FIRESTORE_FACEENROLLMENT_ADD";

export const FIRESTORE_FACEENROLLMENT_DELETE = "FIRESTORE_FACEENROLLMENT_DELETE";

export const FIRESTORE_NETWORKSETTINGS_ADD = "FIRESTORE_NETWORKSETTINGS_ADD";

export const FIRESTORE_NETWORKSETTINGS_DELETE = "FIRESTORE_NETWORKSETTINGS_DELETE";

export const FIRESTORE_CARDENROLLMENT_ADD = "FIRESTORE_CARDENROLLMENT_ADD";

export const FIRESTORE_CARDENROLLMENT_DELETE = "FIRESTORE_CARDENROLLMENT_DELETE";

export const FIRESTORE_DOORRING_SAVE = "FIRESTORE_DOORRING_SAVE";
export const FIRESTORE_DOORRING_DELETE = "FIRESTORE_DOORRING_DELETE";

//change portal
export const CHANGE_PORTAL = "CHANGE_PORTAL";

//change language
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

// verify type of device
export const VERIFY_TYPE_OF_DEVICE = "VERIFY_TYPE_OF_DEVICE";
//general
export const RESET_GENERALSTORE_VALUES = "RESET_GENERALSTORE_VALUES";
export const ADD_DEVICE_WEBRTC_ACTIVE_CONNECTION = "ADD_DEVICE_WEBRTC_ACTIVE_CONNECTION";
export const DELETE_DEVICE_WEBRTC_ACTIVE_CONNECTION = "DELETE_DEVICE_WEBRTC_ACTIVE_CONNECTION";
export const IS_ACTIVE_GENERAL_LOCKDOWN = "IS_ACTIVE_GENERAL_LOCKDOWN";
export const IS_LOCKDOWN_PLAN_SETUP = "IS_LOCKDOWN_PLAN_SETUP";
export const DID_CALL_API_LOCKDOWN_INFO = "DID_CALL_API_LOCKDOWN_INFO";
export const LIVE_STRING_FROM_DEVICE = "LIVE_STRING_FROM_DEVICE";
export const DOWNLOAD_VIDEO_FROM_CLOUD = "DOWNLOAD_FROM_CLOUD";
export const DOWNLOAD_VIDEO_FROM_CLOUD_PERCENT = "DOWNLOAD_VIDEO_FROM_CLOUD_PERCENT";
export const UPDATE_APPROVAL_SETTINGS = "UPDATE_APPROVAL_SETTINGS";
