export const DeviceValidation = {
  minNameChar: 2,
  maxNameChar: 50,

  minMacAddress: 12,
  minSerialNumber: 13,
};

export const EmailValidation = {
  minChar: 0,
  maxChar: 150,
};

export const PhoneNumberValidation = {
  supportedCountries: [] as string[],
};

export const UserValidation = {
  minNameChar: 0,
  maxNameChar: 50,
};
export const GroupValidation = {
  minNameChar: 0,
  maxNameChar: 50,
  minDescriptionChar: 0,
  maxDescriptionChar: 150,
};
export const ZoneValidation = {
  minNameChar: 0,
  maxNameChar: 50,
  minDescriptionChar: 0,
  maxDescriptionChar: 150,
};
export const ScheduleValidation = {
  minNameChar: 0,
  maxNameChar: 50,
  minDescriptionChar: 0,
  maxDescriptionChar: 150,
};

export const RoleValidation = {
  minNameChar: 0,
  maxNameChar: 50,
  minDescriptionChar: 0,
  maxDescriptionChar: 150,
};
export const SiteValidation = {
  minNameChar: 0,
  maxNameChar: 50,
  minDescriptionChar: 0,
  maxDescriptionChar: 150,
};
export const AccountValidation = {
  minAccountNameChar: 4,
  maxAccountNameChar: 100,

  minFirstNameChar: 2,
  maxFirstNameChar: 20,

  minLastNameChar: 2,
  maxLastNameChar: 20,
};

export const BillingValidation = {
  minaddressLine1Char: 3,
  maxaddressLine1Char: 100,

  minCityChar: 1,
  maxCityChar: 100,

  minZipCodeChar: 1,
  maxZipCodeChar: 100,
};

export const HolidaysValidation = {
  minNameChar: 0,
  maxNameChar: 50,
};

export const AddressValidation = {
  minLineChar: 0,
  maxLineChar: 75,
  minPostalCodeChar: 0,
  maxPostalCodeChar: 10,
  minCityChar: 1,
  maxCityChar: 50,
};

export const WorkflowValidation = {
  minDescriptionChar: 4,
  maxDescriptionChar: 100,
};
export const CatalogValidation = {
  minNameChar: 0,
  maxNameChar: 50,
  minDescriptionChar: 0,
  maxDescriptionChar: 100,
};
export const SecondsToDiscardDoorRing = 30;
export const SecondsToDiscardNFCTagRing = 30;
export const SensorValidation = {
  minNameChar: 0,
  maxNameChar: 50,
  minDescriptionChar: 0,
  maxDescriptionChar: 150,
};
export const SensorGoodBattery = 80;
export const SensorBadBattery = 20;

export const SensorGoodSignalIntensity = 75;
export const SensorBadSignalIntensity = 50;
export const SensorMaxNumberOfExitButtons = 6;
export const MassMessagingValidation = {
  minChar: 4,
  maxChar: 300,
};
export const VideoWallNameValidation = {
  minChar: 1,
  maxChar: 50,
};
