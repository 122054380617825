import moment, { Moment } from "moment";
import { QueryFilter } from "../Models/api";
import {
  CredentialType,
  DoorStateType,
  LockStateType,
  ObjectDetectionType,
} from "../Models/devices";
import {
  NotificationDoorBell,
  NotificationDoorStateEnum,
  NotificationLockStateEnum,
} from "../Models/notifications";
import { AccessLogResultType, AccessLogsModel } from "../Models/accessLog";
import { CronScheduleModel } from "../Models/schedules";
import { EWorkflowActorType, EWorkflowCodeTypes } from "../Models/workflow";
import { ECardEndianType, PortalIds } from "../Models/account";
import { generalPortalRoutes, pathRoute } from "../Routes/routes";
import { SensorType } from "../Models/sensor";
import { CatalogUnitType } from "../Models/catalog";

export const hasRole = (user: any, roles: any): boolean =>
  roles.some((role: any) => user.roles.includes(role));

export function updateObject(oldObject: any, updatedProperties: any): any {
  return {
    ...oldObject,
    ...updatedProperties,
  };
}
export function obtainModelFromSerialNumber(serial: string): string {
  return "unknow model";
}
export function obtainTypeFromSerialNumber(serial: string): string {
  return "unknow type";
}
export const fiteToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// await new Promise((r) => setTimeout(r, 500));

export function obtainStartTimeFromCronSchedule(cronSchedules: CronScheduleModel): string {
  const expresion = cronSchedules.cron_expression.split(" ");
  //const hours = expresion[2];
  let minutes = expresion[1];
  if (expresion[1] === "0") {
    minutes = "00";
  }
  const tempMin = parseInt(expresion[1]);
  if (tempMin > 0 && tempMin < 9) {
    minutes = "0" + minutes;
  }
  let amPM = "am";
  let hourInt = parseInt(expresion[2]);
  if (hourInt > 12) {
    amPM = "pm";
    hourInt = hourInt - 12;
  }
  if (hourInt == 0) {
    hourInt = 12; //this to show 12:xx am and not 00:xx am
  }
  return hourInt.toString() + " : " + minutes + " " + amPM; //Here we have startTime
}

export function obtainEndTimeFromCronSchedule(cronSchedules: CronScheduleModel): string {
  const expresion = cronSchedules.cron_expression.split(" ");
  const minutes = parseInt(expresion[1]);
  const hours = parseInt(expresion[2]);
  const mm = moment().hour(hours).minute(minutes);
  mm.add(cronSchedules.time_duration, "minutes");
  let tempMinutes = mm.get("minute").toString();
  const temp = mm.get("minute");
  if (tempMinutes === "0") {
    tempMinutes = "00";
  }
  if (temp > 0 && temp < 9) {
    tempMinutes = "0" + tempMinutes;
  }
  let amPM = "am";
  let hourInt = mm.get("hour");
  if (hourInt >= 12) {
    amPM = "pm";
    hourInt = hourInt - 12;
  }
  if (hourInt == 0) {
    hourInt = 12; //this to show 12:xx am and not 00:xx am
  }
  const endTime = hourInt.toString() + " : " + tempMinutes + " " + amPM;
  return endTime;
}
export function obtainStartDateFromCronSchedule(cronSchedules: CronScheduleModel): string {
  return moment(cronSchedules.start).format("LL");
}
export function obtainEndDateFromCronSchedule(cronSchedules: CronScheduleModel): string {
  return moment(cronSchedules.end).format("LL");
}

export function extractDaysOfTheWeekFromCronExpression(cronExpression: string): string[] {
  const parts = cronExpression.split(" ");
  if (parts.length < 5) {
    return ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  }
  const array = parts[5].split(",");
  if (array.length === 1 && array[0] === "") {
    return ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  }
  return array;
}
export function classifyDays(days: string[]) {
  const allDays = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  const weekdays = ["MON", "TUE", "WED", "THU", "FRI"];
  const weekends = ["SAT", "SUN"];

  const daysSet = new Set(days);

  if (daysSet.size === allDays.length && allDays.every((day) => daysSet.has(day))) {
    return "EveryDay";
  }
  if (weekdays.every((day) => daysSet.has(day)) && daysSet.size === weekdays.length) {
    return "Weekdays";
  }
  if (weekends.every((day) => daysSet.has(day)) && daysSet.size === weekends.length) {
    return "Weekends";
  }
  return "Every";
}

export function extractStartTimeAsDateFromCronExpression(cronExpression: string): moment.Moment {
  const parts = cronExpression.split(" ");
  const minute = parseInt(parts[1], 10);
  const hour = parseInt(parts[2], 10);

  const startTime = moment().hours(hour).minutes(minute).seconds(0).milliseconds(0);

  return startTime;
}
export function createCronExpression(
  startTime: Moment,
  sunday: boolean,
  monday: boolean,
  tuesday: boolean,
  wednesday: boolean,
  thursday: boolean,
  friday: boolean,
  saturday: boolean
): string {
  let cronExpression = "";
  const dayOfWeek = [];
  dayOfWeek[0] = sunday ? "SUN" : "";
  dayOfWeek[1] = monday ? "MON" : "";
  dayOfWeek[2] = tuesday ? "TUE" : "";
  dayOfWeek[3] = wednesday ? "WED" : "";
  dayOfWeek[4] = thursday ? "THU" : "";
  dayOfWeek[5] = friday ? "FRI" : "";
  dayOfWeek[6] = saturday ? "SAT" : "";
  while (dayOfWeek.indexOf("") >= 0) {
    dayOfWeek.splice(dayOfWeek.indexOf(""), 1);
  }
  //changed the first * to 0 because Andy said it will be more faster in the device
  cronExpression =
    "0 " + startTime.minute() + " " + startTime.hours() + " ? * " + dayOfWeek.toString() + " *";
  return cronExpression;
}
export function createCronExpressionWorkflow(
  sunday: boolean,
  monday: boolean,
  tuesday: boolean,
  wednesday: boolean,
  thursday: boolean,
  friday: boolean,
  saturday: boolean
): string {
  let cronExpression = "";
  const dayOfWeek = [];
  dayOfWeek[0] = sunday ? "SUN" : "";
  dayOfWeek[1] = monday ? "MON" : "";
  dayOfWeek[2] = tuesday ? "TUE" : "";
  dayOfWeek[3] = wednesday ? "WED" : "";
  dayOfWeek[4] = thursday ? "THU" : "";
  dayOfWeek[5] = friday ? "FRI" : "";
  dayOfWeek[6] = saturday ? "SAT" : "";
  while (dayOfWeek.indexOf("") >= 0) {
    dayOfWeek.splice(dayOfWeek.indexOf(""), 1);
  }
  cronExpression = "* " + "*" + " " + "*" + " ? * " + dayOfWeek.toString() + " *";
  return cronExpression;
}
export function checkIfDayOfWeekExist(
  day: "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT",
  cronExpression?: string
): boolean {
  if (!cronExpression) {
    return false;
  }
  const expresion = cronExpression.split(" ");
  const array = expresion[5].split(",");
  if (array.some((element) => element === day)) {
    return true;
  }
  return false;
}
export function createTimeDuration(startTime: Moment, endTime: Moment): number {
  const duration = moment.duration(startTime.diff(endTime));
  return duration.asMinutes();
}

export function cleanIPaddress(address: string): string {
  const subips = address.split(".");
  const result =
    parseInt(subips[0]) +
    "." +
    parseInt(subips[1]) +
    "." +
    parseInt(subips[2]) +
    "." +
    parseInt(subips[3]);

  return result;
}

export function hasTheCredentialTheIdentificationWorkflow(
  identifications: number[][],
  credential: CredentialType
): boolean {
  if (!identifications) {
    return false;
  }

  let hasTheCredential = false;
  for (let i = 0; i < identifications.length; i++) {
    if (identifications[i].findIndex((value) => value == credential) > -1) {
      hasTheCredential = true;
      break;
    }
  }
  return hasTheCredential;
}

export function showTheTextDependingNotification(code: string, data: any): string {
  if (code === NotificationDoorStateEnum.NotificationDoorState) {
    switch (data) {
      case DoorStateType.Closed:
        return "Closed";
      case DoorStateType.OpenVerified:
        return "OpenVerified";
      case DoorStateType.OpenRequestToExit:
        return "OpenRequestToExit";
      case DoorStateType.OpenForcedEntry:
        return "OpenForcedEntry";
      case DoorStateType.OpenTimedOut:
        return "OpenTimedOut";
      case DoorStateType.NoSensor:
        return "NoSensor";
      default:
        return "NoSensor";
    }
  } else if (code === NotificationLockStateEnum.NotificationLockState) {
    switch (data) {
      case LockStateType.Locked:
        return "Locked";
      case LockStateType.Unlocked:
        return "Unlocked";
      case LockStateType.Lockdown:
        return "Lockdown";
      case LockStateType.KeepOpen:
        return "KeepOpen";
      case LockStateType.UnlockedWithSchedule:
        return "UnlockedWithSchedule";
      case LockStateType.UnlockedRequestToExit:
        return "UnlockedRequestToExit";
      default:
        return "Unlocked";
    }
  } else if (code === NotificationDoorBell.NotificationDoorBellRing) {
    const deviceName = JSON.parse(data).DeviceName;
    return deviceName;
  } else {
    return "";
  }
}

export const validateNotificationWithAccessLogFilter = (
  filter: QueryFilter,
  notification: AccessLogsModel,
  userSiteIds: number[]
): boolean => {
  /** Validate current page */
  if (filter.pageNumber !== 1 && filter.pageNumber !== 0) {
    return false;
  }

  /** Validate serial */
  if (filter.serialNumber && filter.serialNumber !== notification.serialNumber) {
    return false;
  }

  /** Validate dates */
  if (
    filter.from &&
    !(
      moment(notification.time).valueOf() > moment(filter.from).valueOf() &&
      moment(notification.time).valueOf() <= moment(filter.to).valueOf()
    )
  ) {
    return false;
  }

  /** Validate sites */
  let isTheNotificationSiteOnUserSites = false;
  for (let index = 0; index < userSiteIds.length; index++) {
    if (notification.siteId === userSiteIds[index]) {
      isTheNotificationSiteOnUserSites = true;
      break;
    }
  }
  if (!isTheNotificationSiteOnUserSites) {
    return false;
  }

  return true;
};

export const cleanBaseImage = (base64: string): string => base64.split(",")[1];

export function convertActorToString(actor: EWorkflowActorType): string {
  switch (actor) {
    case EWorkflowActorType.GeneralLockDown:
      return "GeneralLockDown";
    case EWorkflowActorType.GeneralSoundAlarm:
      return "GeneralSoundAlarm";
    case EWorkflowActorType.LockDownDoor:
      return "LockDownDoor";
    case EWorkflowActorType.SendEmail:
      return "SendEmail";
    case EWorkflowActorType.SendSMS:
      return "SendSMS";
    case EWorkflowActorType.SoundDoorAlarm:
      return "SoundDoorAlarm";
    case EWorkflowActorType.CaptureImage:
      return "CaptureImage";
    case EWorkflowActorType.CaptureVideo:
      return "CaptureVideo";
    case EWorkflowActorType.IssueSensorCommand:
      return "IssueSensorCommand";
    default:
      return "None";
  }
}

export function checkDateComparison(startDate: Moment, endDate: Moment): string {
  if (startDate && startDate.isValid() && endDate && endDate.isValid()) {
    const tempStart = startDate.second(0).millisecond(0);
    const tempEnd = endDate.second(0).millisecond(0);
    if (tempEnd.diff(tempStart) == 0) {
      return "ErrorTimeMustBeDifferent";
    }
  } else {
    if (startDate && !startDate.isValid()) {
      return "InvalidDate";
    }
    if (endDate && !endDate.isValid()) {
      return "InvalidDate";
    }
  }
  return "";
}

export function mapTriggerCodeToTranslationCode(trigger: EWorkflowCodeTypes): string {
  switch (trigger) {
    case EWorkflowCodeTypes.wf_accesslog_unauthorized:
      return "wf_accesslog_unauthorized";
    case EWorkflowCodeTypes.wf_door_keepopen:
      return "wf_door_keepopen";
    case EWorkflowCodeTypes.wf_door_offline:
      return "wf_door_offline";
    case EWorkflowCodeTypes.wf_door_openremote:
      return "wf_door_openremote";
    case EWorkflowCodeTypes.wf_door_settingschanged:
      return "wf_door_settingschanged";
    case EWorkflowCodeTypes.wf_personofinterest_observed:
      return "wf_personofinterest_observed";
    case EWorkflowCodeTypes.wf_door_forceopen:
      return "wf_door_forceopen";
    case EWorkflowCodeTypes.wf_accesslog_authorized:
      return "wf_accesslog_authorized";
    case EWorkflowCodeTypes.wf_lockdown_start:
      return "wf_lockdown_start";
    case EWorkflowCodeTypes.wf_lockdown_end:
      return "wf_lockdown_end";
    case EWorkflowCodeTypes.wf_sensor_triggered:
      return "wf_sensor_triggered";
    case EWorkflowCodeTypes.wf_ioerror_detected:
      return "wf_ioerror_detected";
    case EWorkflowCodeTypes.wf_integration_error:
      return "wf_integration_error";
    case EWorkflowCodeTypes.wf_mobile_accessnotgranted:
      return "wf_mobile_accessnotgranted";
    default:
      return "None";
  }
}
export function mapAccessLogTypeToTranslation(type: AccessLogResultType): string {
  switch (type) {
    case AccessLogResultType.AccessGranted:
      return "AccessGranted";
    case AccessLogResultType.NotScheduled:
      return "DeniedNotScheduled";
    case AccessLogResultType.UnknownUser:
      return "DeniedUnknownUser";
    case AccessLogResultType.DeviceInLockdown:
      return "DeniedDeviceInLockdown";
    case AccessLogResultType.NonLiveUser:
      return "DeniedNonLiveUser";
    case AccessLogResultType.CardFormatError:
      return "DeniedCardFormatError";
    case AccessLogResultType.LockdownStart:
      return "LockdownStarted";
    case AccessLogResultType.LockdownEnd:
      return "LockdownEnded";
    case AccessLogResultType.Doorbell:
      return "Doorbell";
    case AccessLogResultType.Suspended:
      return "DeniedSuspended";
    default:
      return "None";
  }
}

export function mapCatalogUnitType(type: CatalogUnitType): string {
  switch (type) {
    case CatalogUnitType.Unit:
      return "Unit";
    case CatalogUnitType.Apt:
      return "Apt";
    case CatalogUnitType.Home:
      return "Home";
    case CatalogUnitType.Lobby:
      return "Lobby";

    default:
      return "None";
  }
}

export function mapCardEndianTypeToTranslation(type: ECardEndianType): string {
  switch (type) {
    case ECardEndianType.None:
      return "None";
    case ECardEndianType.Big:
      return "Big";
    case ECardEndianType.Little:
      return "Little";
    default:
      return "None";
  }
}

export function hideElementBecauseLocationIsPublic(pathname: string): boolean {
  if (pathname === pathRoute.SignIn.Index || pathname === pathRoute.SignUp.Index) {
    return false;
  }
  //the generalPortalRoutes are private routes
  const valueAccessControl = generalPortalRoutes.some((route) => route.path === pathname);

  const valueCameras = generalPortalRoutes.some((route) => route.path === pathname);

  const valueGateway = generalPortalRoutes.some((route) => route.path === pathname);

  if (valueAccessControl || valueCameras || valueGateway) {
    return false;
  }
  return true;
}
export function convertSensorTypeToString(actor: SensorType): string {
  switch (actor) {
    case SensorType.Contact:
      return "Contact";
    case SensorType.Motion:
      return "Motion";
    case SensorType.Relay:
      return "WirelessRelay";
    case SensorType.PushButton:
      return "PushPanicExitButton";
    case SensorType.LightSwitch:
      return "LightSwitch";
    case SensorType.Alarm:
      return "SirenAlarm";
    default:
      return "None";
  }
}

export function convertSensorIntensityToPercent(intensity: number): string {
  let value = 0;
  if (intensity) {
    let temp = intensity;
    if (intensity > 255) {
      temp = 255;
    }
    value = temp / 2.55;
  }
  return value.toFixed(0);
}

export function generateColorPerObjectDetection(value: ObjectDetectionType) {
  if (value === ObjectDetectionType.Person) {
    return "lightgreen";
  } else if (value === ObjectDetectionType.Vehicle) {
    return "lightcoral";
  } else if (value === ObjectDetectionType.Animals) {
    return "darkblue";
  } else {
    return "black";
  }
}

export function generateMarginTopPerObjectDetection(value: ObjectDetectionType) {
  if (value === ObjectDetectionType.Person) {
    return 2;
  } else if (value === ObjectDetectionType.Vehicle) {
    return 4;
  } else if (value === ObjectDetectionType.Animals) {
    return 6;
  } else {
    return 8;
  }
}
