import { InfoToDownloadFromCloud } from "./devices";
import { NotificationModel } from "./notifications";

declare global {
  interface Navigator {
    msMaxTouchPoints: number;
  }
}

export default interface StoreStateModel {
  layout: LayoutReducerModel;
  general: GeneralReducerModel;
  auth: AuthReducerModel;
  notification: ReducerNotificationModel;
}
export interface LayoutReducerModel {
  isOpenSideBar: boolean;
  prefersDarkMode: boolean;
  innerHeight: number;
  innerWidth: number;
  sideBarCamerasPosition: number | undefined;
}
export interface GeneralReducerModel {
  showLoadingComponent: boolean;
  interceptorErrorMessage: string;
  interceptorErrorId: number;
  portalId: string;
  totalDevicesConnectedWebRTC: string[];
  isGeneralLockdownActivated: boolean;
  generalLockdownStartTime: string;
  generalLockdownDuration: number;
  isLockdownPlanSetUp: boolean;
  didCallAPILockdownInfo: boolean;
  liveStringFromDevice: MediaStream | null;
  pendingDeviceInfoDownloadFromCloud?: InfoToDownloadFromCloud;
  downloadingPercent: number;
}

export interface AuthReducerModel {
  uid: string;
  token: string;
  scopes: string[];
  userName: string;
  accountName: string;
  email: string;
  isAdmin: boolean;
  roleName: string;
  roleId: number;
  roleRank: number;

  language: string;
  accountId: number;
  isEmailVerified: boolean;
  accountStatus: string;
  siteIds: number[];
  siteNames: string[];

  deviceType: ScreenType;
  isIOS: boolean;
  hasTouchScreen: boolean;

  isSigningIn: boolean;
  isAuthenticated: boolean;
  signInErrorCode: string;

  isSigningOut: boolean;
  signOutErrorCode: string;

  isVerifying: boolean;

  isReauthenticateCredentialRequesting: boolean;
  isReauthenticateCredentialSuccess: boolean;
  reauthenticateCredentialErrorCode: string;

  isUpdatePasswordRequesting: boolean;
  isUpdatePasswordSuccess: boolean;
  updatePasswordErrorCode: string;

  isSignUpRequesting: boolean;
  isSignUpSuccess: boolean;
  signUpErrorCode: string;

  isVerifyEmailRequesting: boolean;
  isVerifyEmailSuccess: boolean;
  verifyEmailErrorCode: string;

  isVerifyPasswordResetCodeRequesting: boolean;
  isVerifyPasswordResetCodeSuccess: boolean;
  verifyPasswordResetCodeErrorCode: string;
  emailPasswordToReset: string;

  isConfirmPasswordResetRequesting: boolean;
  isConfirmPasswordResetSuccess: boolean;
  confirmPasswordResetErrorCode: string;

  isSendVerificationEmailRequesting: boolean;
  isSendVerificationEmailSuccess: boolean;
  sendVerificationEmailErrorCode: string;

  //authorizations
  hasPermissionAccessLogsRead: boolean;

  hasPermissionUserRead: boolean;
  hasPermissionUserWrite: boolean;

  hasPermissionRoleRead: boolean;

  hasPermissionGroupRead: boolean;
  hasPermissionGroupWrite: boolean;

  hasPermissionCredentialRead: boolean;
  hasPermissionCredentialWrite: boolean;

  hasPermissionDoorRead: boolean;
  hasPermissionDoorWrite: boolean;
  hasPermissionSiteRead: boolean;
  hasPermissionScheduleWrite: boolean;
  hasPermissionDoorRemoteAccessWrite: boolean;
  hasPermissionDoorSettingsWrite: boolean;
  hasPermissionAccountPaymentMethodRead: boolean;
  hasPermissionAccountPaymentMethodWrite: boolean;
  hasPermissionSiteWrite: boolean;
  hasPermissionTimezoneRead: boolean;
  hasPermissionZoneWrite: boolean;
  hasPermissionZoneRead: boolean;
  hasPermissionSettings: boolean;
  hasPermissionSupportArea: boolean;
  hasPermissionScheduleRead: boolean;

  hasPermissionSubscriptionWrite: boolean;
  hasPermissionAccountRead: boolean;
  hasPermissionAccountWrite: boolean;
  hasPermissionLockdownActivation: boolean;

  hasPermissionMobileRead: boolean;
  hasPermissionMobileWrite: boolean;

  hasCamerasFeature: boolean;
  hasSensorsFeature: boolean;
  hasMasNotificationFeature: boolean;
  hasElevatorFeature: boolean;
  hasPassRequiresApprovalSettingsFeature: boolean;
  hasIntercomFeature: boolean;
}

export interface ReducerNotificationModel {
  tokenPushNotification: string;
  isNotificationEnabled: boolean;
  listenerPushNotification: any;
  pushNotifications: NotificationModel[];

  isTokenPushNotificationRequesting: boolean;
  isTokenPushNotificationSuccess: boolean;
  pushNotificationErrorCode: string;

  totalFaceEnrollmentSubscriptions: string[];

  totalNetworkSettingsSubscriptions: string[];

  totalCardEnrollmentSubscriptions: string[];

  totalDoorRingSerials: string[];
}

export enum ScreenType {
  Mobile = "Mobile",
  Desktop = "Desktop",
}
