import React, { Fragment, useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import Header from "./Header/Header";
import SideBarNav from "./SideBarNav/SideBarNav";
import MainContent from "./MainContent/MainContent";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import StoreStateModel from "../Utils/Models/store";
import { getAccountCampaign } from "../Utils/API/apiSettings";
import UpdateCampaign from "./Header/UpdateCampaign";
import BarLockdownActivated from "./Header/BarLockdownActivated";
import moment from "moment";
import axios from "axios";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {},
  })
);
interface Props {
  children: any;
  sideBarNavList: any;
}
function Layout(props: Props) {
  const classes = useStyles();

  const openSideBarNav = useSelector(
    (state: StoreStateModel) => state.layout.isOpenSideBar,
    shallowEqual
  );

  const sideBarCamerasPosition = useSelector(
    (state: StoreStateModel) => state.layout.sideBarCamerasPosition,
    shallowEqual
  );

  const prefersDarkMode = useSelector(
    (state: StoreStateModel) => state.layout.prefersDarkMode,
    shallowEqual
  );

  const dispatch = useDispatch();

  const firstTime = useRef(true);

  //this is to close the sidebar when there is a transition to mobile screen
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    if (matches == false && firstTime.current === false) {
      dispatch(actions.changeSideBar(!openSideBarNav));
    }
  }, [matches]);
  ////

  ////////////////////////////////////

  ///////////////////////////////////
  useEffect(() => {
    firstTime.current = false;
  }, []);
  ///////////////////////////
  const hasPermissionAccountRead: boolean = useSelector(
    (state: StoreStateModel) => state.auth.hasPermissionAccountRead,
    shallowEqual
  );
  const [textOfCounter, setTextOfCounter] = useState("");
  const [showNavigationBar, setShowNavigationBar] = useState(false);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAccountsFW = async () => {
      try {
        const response: any = await getAccountCampaign(source.token);
        if (!response.data.hasErrors) {
          const temp = response.data.payload;
          if (temp) {
            setShowNavigationBar(true);
            setTextOfCounter(moment(temp.expiration + "Z").format("MMM Do"));
          }
        }
      } catch (error) {
        //
      } finally {
        //
      }
    };
    if (hasPermissionAccountRead) {
      getAccountsFW();
    }
  }, []);

  const componentNavigationBar = (
    <UpdateCampaign
      onClickCloseIcon={() => setShowNavigationBar(false)}
      textOfCounter={textOfCounter}
    />
  );

  const isGeneralLockdownActivated: boolean = useSelector(
    (state: StoreStateModel) => state.general.isGeneralLockdownActivated,
    shallowEqual
  );
  const generalLockdownStartTime: string = useSelector(
    (state: StoreStateModel) => state.general.generalLockdownStartTime,
    shallowEqual
  );
  const generalLockdownDuration: number = useSelector(
    (state: StoreStateModel) => state.general.generalLockdownDuration,
    shallowEqual
  );
  const navigationBarLockdown = (
    <BarLockdownActivated
      startTime={generalLockdownStartTime}
      durationInMinutes={generalLockdownDuration}
    />
  );
  ///////////////////////////
  const showBar = showNavigationBar || isGeneralLockdownActivated;

  //////////////////////////
  useEffect(() => {
    const updateWindowDimensions = () => {
      dispatch(actions.changesInWindowDimenssions(window.innerHeight, window.innerWidth));
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);
  /////////////////////////
  return (
    <Fragment>
      <div className={classes.wrapper}>
        <Header
          showNavigationBar={showBar}
          componentNavigationBar={
            isGeneralLockdownActivated ? navigationBarLockdown : componentNavigationBar
          }
          restricted={false}
          handleOpenSideBarNavClicked={() => {
            dispatch(actions.changeSideBar(!openSideBarNav));
          }}
          changeTheme={dispatch(actions.changeTheme)}
          prefersDarkMode={prefersDarkMode}
        />

        <SideBarNav
          isShowingNavigationBar={showBar}
          handleOpenSideBarNavClicked={() => {
            dispatch(actions.changeSideBar(!openSideBarNav));
          }}
          openSideBarNav={openSideBarNav}
          handleDrawerToggle={() => dispatch(actions.changeSideBar(false))}
        >
          {props.sideBarNavList}
        </SideBarNav>

        <MainContent
          isShowingNavigationBar={showBar}
          openSideBarNav={openSideBarNav}
          sideBarCamerasPosition={sideBarCamerasPosition}
        >
          {props.children}
        </MainContent>
      </div>
    </Fragment>
  );
}

export default Layout;
