import React from "react";
import Card from "@material-ui/core/Card";
import { Button, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { pathRoute } from "../Routes/routes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
    },
    card: {
      maxWidth: 545,
    },
    content: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
    },
    cover: {},
    title: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
export default function Page404() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container className={classes.root}>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Grid item className={classes.title}>
              <Typography gutterBottom variant="h2" component="h2" display="initial">
                {t("PageNotFound")}
              </Typography>
            </Grid>
            <Grid item className={classes.content}>
              <Typography variant="body2" color="textSecondary">
                {t("PageNotFoundSubtitle")}
              </Typography>
            </Grid>
            {/* <CardMedia
              image="/images/pageNotFound.jpg"
              title="question"
              component="img"
              alt="question mark"
              height="600"
            /> */}
            <CardActions className={classes.content}>
              <Button
                color="primary"
                onClick={() => {
                  window.open(pathRoute.Dashboard);
                }}
              >
                {t("GoDashboard")}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
