import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../components/Utils/Custom/HelperCode";
import { LayoutReducerModel } from "../../components/Utils/Models/store";

// interface LayoutReducerModel {
//   isOpenSideBar: boolean
//   prefersDarkMode: boolean
// }
const initialState: LayoutReducerModel = {
  isOpenSideBar: true,
  prefersDarkMode: false,
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  sideBarCamerasPosition: undefined,
};

//////////////Reducer Slice//////////////////////
const changeSideBar_Reducer = (state: LayoutReducerModel, action: any) => {
  return updateObject(state, { isOpenSideBar: action.value });
};
const changeSideBarCameras_Reducer = (state: LayoutReducerModel, action: any) => {
  return updateObject(state, { sideBarCamerasPosition: action.value });
};
const changeTheme_Reducer = (state: LayoutReducerModel, action: any) => {
  return updateObject(state, { prefersDarkMode: !state.prefersDarkMode });
};
const changeWindowDimenssions_Reducer = (state: LayoutReducerModel, action: any) => {
  // console.log("window.innerHeight", action.value.innerHeight);
  // console.log("window.innerWidth", action.value.innerWidth);
  return updateObject(state, {
    innerHeight: action.value.innerHeight,
    innerWidth: action.value.innerWidth,
  });
};
/////REDUCER//////
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SIDEBARNAV_POSITION:
      return changeSideBar_Reducer(state, action);
    case actionTypes.SIDEBARNAVCAMERAS_POSITION:
      return changeSideBarCameras_Reducer(state, action);
    case actionTypes.CHANGE_THEME:
      return changeTheme_Reducer(state, action);
    case actionTypes.CHANGE_WINDOW_DIMENSSIONS:
      return changeWindowDimenssions_Reducer(state, action);
    default:
      return state;
  }
};
export default reducer;
