import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import firebase from "firebase";
import { NotificationModel } from "../../Models/notifications";

export default function useFireStoreSubscription(
  documentScope: string,
  collectionNotification: string,
  dataCallback: (data: NotificationModel | NotificationModel[]) => void,
  documentSerialNumber?: string,
  provisionalAccountId?: string,
  onNoDataCallBack?: () => void
) {
  const accountId =
    provisionalAccountId ||
    useSelector((state: any) => state.auth.accountId, shallowEqual).toString();

  useEffect(() => {
    const db = firebase.firestore();
    let unsub: any;
    if (typeof documentSerialNumber === "string") {
      if (documentSerialNumber.length > 0) {
        unsub = db
          .collection(accountId)
          .doc(documentScope)
          .collection(collectionNotification)
          .doc(documentSerialNumber)
          .onSnapshot(
            { includeMetadataChanges: false },
            (snapshot: any) => {
              if (snapshot.data()) {
                const data: NotificationModel = {
                  id: documentSerialNumber,
                  Data: snapshot.data().Data ? JSON.parse(snapshot.data().Data) : null,
                  Code: snapshot.data().Code,
                };
                dataCallback(data);
              } else {
                onNoDataCallBack && onNoDataCallBack();
              }
            },
            (error: any) => {
              console.error("error-document", error.message);
            }
          );
      }
    } else {
      unsub = db
        .collection(accountId)
        .doc(documentScope)
        .collection(collectionNotification)
        .onSnapshot(
          { includeMetadataChanges: false },
          (snapshot: any) => {
            const data: NotificationModel[] = snapshot.docs.map((doc: any) => {
              return {
                id: doc.id,
                Data: doc.data().Data ? JSON.parse(doc.data().Data) : null,
                Code: doc.data().Code,
              } as NotificationModel;
            });
            dataCallback(data);
          },
          (error: any) => {
            console.error("error-collection", error.message);
          }
        );
    }
    return () => unsub && unsub();
  }, [documentSerialNumber]);
}
export function useFireStoreSubscriptionPassingAccountId(
  accountId: string,
  documentScope: string,
  collectionNotification: string,
  dataCallback: (data: NotificationModel | NotificationModel[]) => void,
  documentSerialNumber?: string,
  provisionalAccountId?: string
) {
  useEffect(() => {
    const db = firebase.firestore();
    let unsub: any;
    if (accountId) {
      if (typeof documentSerialNumber === "string") {
        if (documentSerialNumber.length > 0) {
          unsub = db
            .collection(accountId)
            .doc(documentScope)
            .collection(collectionNotification)
            .doc(documentSerialNumber)
            .onSnapshot(
              { includeMetadataChanges: false },
              (snapshot: any) => {
                if (snapshot.data()) {
                  const data: NotificationModel = {
                    id: documentSerialNumber,
                    Data: snapshot.data().Data ? JSON.parse(snapshot.data().Data) : null,
                    Code: snapshot.data().Code,
                  };
                  dataCallback(data);
                }
              },
              (error: any) => {
                console.error("error-document", error.message);
              }
            );
        }
      } else {
        unsub = db
          .collection(accountId)
          .doc(documentScope)
          .collection(collectionNotification)
          .onSnapshot(
            { includeMetadataChanges: false },
            (snapshot: any) => {
              const data: NotificationModel[] = snapshot.docs.map((doc: any) => {
                return {
                  id: doc.id,
                  Data: doc.data().Data ? JSON.parse(doc.data().Data) : null,
                  Code: doc.data().Code,
                } as NotificationModel;
              });
              dataCallback(data);
            },
            (error: any) => {
              console.error("error-collection", error.message);
            }
          );
      }
    }
    return () => unsub && unsub();
  }, [documentSerialNumber, accountId]);
}

export function useFireStoreSubscriptionPassingAccountId_1deep(
  accountId: string,
  documentScope: string,
  dataCallback: (data: NotificationModel) => void
) {
  useEffect(() => {
    const db = firebase.firestore();
    let unsub: any;
    if (accountId) {
      unsub = db
        .collection(accountId)
        .doc(documentScope)
        .onSnapshot(
          { includeMetadataChanges: false },
          (snapshot: any) => {
            if (snapshot.data()) {
              const data: NotificationModel = {
                id: "LockdownPlan",
                Data: snapshot.data().Data ? JSON.parse(snapshot.data().Data) : null,
                Code: snapshot.data().Code,
              };
              dataCallback(data);
            }
          },
          (error: any) => {
            console.error("error-document", error.message);
          }
        );
    }
    return () => unsub && unsub();
  }, [accountId]);
}
