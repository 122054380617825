import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints, QueryFilter } from "../Models/api";
import { SiteModel, ZoneModel, HolidaysModel } from "../Models/settings";
import { AccountSettingsModel, MassMessagingModel, UpdateFirmwareModel } from "../Models/account";
import { CancelToken } from "axios";
import { AccountAIEventSettingsModel } from "../Models/devices";
import { ApprovalRequestStatus } from "../Models/notifications";
export const getAllSites = (includeDevices?: boolean, signal?: signal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.siteManagement + "/sites",
    method: axiosMethods.get,
    params: {
      includeDevices: includeDevices,
    },
    signal: signal,
  });
};
export const getAllTimeZones = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.timezoneManagement + "/timezones",
    method: axiosMethods.get,
  });
};
export const addSite = (site: SiteModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.siteManagement + "/site",
    method: axiosMethods.post,
    data: {
      id: 0,
      name: site.name,
      timeZoneId: site.timezoneId,
      description: site.description,
      addressLine1: site.addressLine1,
      addressLine2: site.addressLine2,
      countryISO2: site.countryISO2,
      city: site.city,
      postalCode: site.postalCode,
      stateId: site.stateId,
      externalSiteId: site.externalSiteId,
    },
  });
};
export const deleteASite = (id: number) => {
  return axiosCall(axiosInstance, {
    url: endPoints.siteManagement + "/site/" + id,
    method: axiosMethods.delete,
  });
};
export const updateASite = (site: SiteModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.siteManagement + "/site",
    method: axiosMethods.put,
    data: site,
  });
};

export const getTheSite = (id: number, includeDevices?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.siteManagement + "/site/" + id,
    method: axiosMethods.get,
    params: {
      includeDevices: includeDevices,
    },
  });
};

/////////////
export const addZone = (zone: ZoneModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.zoneManagement + "/zone",
    method: axiosMethods.post,
    data: {
      id: 0,
      name: zone.name,
      description: zone.description,
      siteId: zone.siteId,
    },
  });
};
export const deleteAZone = (id: number) => {
  return axiosCall(axiosInstance, {
    url: endPoints.zoneManagement + "/zone/" + id,
    method: axiosMethods.delete,
  });
};
export const updateAZone = (zone: ZoneModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.zoneManagement + "/zone",
    method: axiosMethods.put,
    data: {
      id: zone.id,
      name: zone.name,
      description: zone.description,
    },
  });
};
export const getTheZone = (id: number) => {
  return axiosCall(axiosInstance, {
    url: endPoints.zoneManagement + "/zone/" + id,
    method: axiosMethods.get,
  });
};
export const getAllZones = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.zoneManagement + "/zones",
    method: axiosMethods.get,
  });
};
//////////////
export const getHolidays = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.holidaysManagement + "/holidays",
    method: axiosMethods.get,
  });
};
export const addHoliday = (holiday: HolidaysModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.holidaysManagement,
    method: axiosMethods.post,
    data: {
      id: 0,
      name: holiday.name,
      month: holiday.month,
      day: holiday.day,
    },
  });
};
export const updateHoliday = (holiday: HolidaysModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.holidaysManagement + "/holiday/" + holiday.id,
    method: axiosMethods.put,
    data: {
      id: holiday.id,
      name: holiday.name,
      month: holiday.month,
      day: holiday.day,
    },
  });
};
export const deleteAHoliday = (id: number) => {
  return axiosCall(axiosInstance, {
    url: endPoints.holidaysManagement + "/holiday/" + id,
    method: axiosMethods.delete,
  });
};

export const getAccountSettings = (signal?: AbortSignal, token?: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/settings",
    method: axiosMethods.get,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined,
    signal: signal,
  });
};

export const updateAccountSettings = (accountSettings: AccountSettingsModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/settings",
    method: axiosMethods.put,
    data: accountSettings,
  });
};

export const getAccountCampaign = (token?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/campaign",
    method: axiosMethods.get,
    cancelToken: token,
  });
};

export const upgradeFirmware = (data: UpdateFirmwareModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.customerSupportManagement + "/devices/firmware",
    method: axiosMethods.put,
    params: {
      isCampaign: true,
    },
    data,
  });
};

export const updateAccountEventSettings = (
  data: AccountAIEventSettingsModel,
  token?: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/event-settings",
    method: axiosMethods.put,
    data,
    cancelToken: token,
  });
};

export const getAccountEventSettings = (token?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/event-settings",
    method: axiosMethods.get,
    cancelToken: token,
  });
};

export const postSendSelfieEnrollmentBatch = (token?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/batch-selfie-enroll",
    method: axiosMethods.post,
    cancelToken: token,
  });
};
export const sendMassMessaging = (data: MassMessagingModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/mass-notification",
    method: axiosMethods.post,
    data: data,
  });
};
export const getPassesByStatus = (status: ApprovalRequestStatus, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/passes/status",
    method: axiosMethods.get,
    signal: signal,
    params: {
      status,
    },
  });
};
export const rejectPassRequest = (
  id: string,
  requestStatus: ApprovalRequestStatus,
  comments: string
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/pass/reject",
    method: axiosMethods.post,
    data: {
      id,
      requestStatus,
      comments,
    },
  });
};
export const approvePassRequest = (
  id: string,
  requestStatus: ApprovalRequestStatus,
  comments: string
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/pass/approve",
    method: axiosMethods.post,
    data: {
      id,
      requestStatus,
      comments,
    },
  });
};
export const getPassById = (id: string, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/passes/" + id,
    method: axiosMethods.get,
    signal: signal,
  });
};

export const getPassesPaged = (filter: QueryFilter, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/passes/paged",
    method: axiosMethods.get,
    params: filter,
    signal: signal,
  });
};

export const getIntegrationPms = (signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/integration/pms",
    method: axiosMethods.get,
    signal: signal,
  });
};

export const getIntegrationPmsProperties = (signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/integration/pms/properties",
    method: axiosMethods.get,
    signal: signal,
  });
};
